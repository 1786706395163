/**
 * helper service to add common navigation. Should be used instead of manually navigating to these different components
 */
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonNavigationsService {

  constructor(private router: Router) { }

  /**
   * navigate to the login page
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
  public navigateToLogin(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/login'], extras);
  }

  /**
   * navigate to the logout page
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
   public navigateToLogout(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/logout'], extras);
  }

  /**
   * navigate to the home dashboard page
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
   public navigateToHome(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/home/dashboard'], extras);
  }

  /**
   * navigate to the root url
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
   public navigateToRoot(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/'], extras);
  }

  /**
   * navigate to the create password page
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
   public navigateToCreatePassword(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/create-password'], extras);
  }

  /**
   * navigate to the MFA page
   * @param extras: optional parameter to set any navigation extras. By default, skipLocationChange will be set to false
   * @returns navigate operation
   */
   public navigateToMFASetup(extras: NavigationExtras = {skipLocationChange: false}) {
    return this.router.navigate(['/mfa-setup'], extras);
  }
}
