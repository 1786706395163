/**
 * handles all Cognito related tasks
 */
import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk';
import { AuthenticationDetails, CognitoUser, CognitoUserSession, IMfaSettings } from 'amazon-cognito-identity-js';
import Pool from './user-pool';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SettingsService } from '@inflight/ps-core-ng';
import { LoadingService } from '../shared/loading-service/loading.service';
import { CommonNavigationsService } from '../shared/common-navigations.service';

export enum AUTHENTICATE_RESULTS {
  SUCCESS,
  FAILURE,
  NEW_PASSWORD,
  MFA_REQUIRED,
  TOTP_REQUIRED,
  MFA_SETUP,
  SELECT_MFA_TYPE
}

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private cognito = new AWS.CognitoIdentityServiceProvider({ region: 'us-west-1' });
  private cognitoUser: CognitoUser;
  private setupMFA: boolean = false;
  private settings: any;
  private username: string;

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private commonNavigations: CommonNavigationsService,
    private loadingService: LoadingService
  ) {
    this.settings = this.settingsService.getExt('partners');
  }

  /**
   * finds the user attributes
   * @param user current user who's logged in
   * @returns javascript object containing all of the user attributes
   */
  private getUserAttributes(user: CognitoUser): Promise<any> {
    return new Promise((resolve, reject) => {
      user.getUserAttributes((err, attributes) => {
        if (err) {
          reject(err);
        } else if (attributes) {
          const results: any = {};
          for (const attribute of attributes) {
            const { Name, Value } = attribute;
            results[Name] = Value;
          }
          resolve(results);
        } else {
          reject(new Error('No user attributes'));
        }
      });
    });
  }

  /**
   * finds the username used to login
   * @returns the email used
   */
  public getUserName(): string | undefined {
    return this.username;
  }

  /**
   * refreshes the current session
   */
  public async refreshSession() {
    const { refreshToken } = await this.getSession();
    const user: CognitoUser = this.getCurrentUser();
    if (user) {
      this.loadingService.setStatus(true);
      user.refreshSession(refreshToken, (err) => {
        if (err) {
          this.logout();
        }
      });
      this.loadingService.setStatus(false);
    }
  }

  /**
   * gets all the information for the current session
   * @returns javascript object containing information about the user, mfa status, headers (for API requests), access token, attributes and session information
   */
  public async getSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.loadingService.setStatus(true);
      const user: CognitoUser = this.getCurrentUser();
      if (user) {
        user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
          if (err) {
            this.loadingService.setStatus(false);
            reject(err);
          } else {
            const attributes: any = await this.getUserAttributes(user).catch((err) => {
              // if rejected, this means that user is not valid and we should sign out to delete app storage
              this.loadingService.setStatus(false);
              this.logout();
              reject(err);
            });

            const accessToken = session?.getAccessToken();

            const mfaEnabled = (accessToken?.getJwtToken()) ? (await new Promise((resolve) => {
              this.cognito.getUser({ AccessToken: accessToken?.getJwtToken() }, (err, data) => {
                if (err) {
                  resolve(false);
                } else {
                  resolve(data.UserMFASettingList?.includes('SOFTWARE_TOKEN_MFA'));
                }
              });
            })) : false;

            const token = session?.getIdToken().getJwtToken();
            this.loadingService.setStatus(false);
            resolve({
              user,
              mfaEnabled,
              headers: {
                'x-api-key': attributes['custom:apikey'],
                Authorization: token
              },
              ...accessToken,
              ...attributes,
              ...session
            });
          }
        });
      } else {
        this.loadingService.setStatus(false);
        reject();
      }
    });
  }

  /**
   * authenticate user with cognito
   * @param Username email used for login
   * @param Password password used for login
   * @returns promise containing data and authentication result state
   */
  public async authenticate(Username: string, Password: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.setupMFA = false;
      this.username = Username;
      const user = new CognitoUser({ Username, Pool });
      this.cognitoUser = user;
      const authDetails = new AuthenticationDetails({ Username, Password });
      this.loadingService.setStatus(true);
      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.SUCCESS, ...data });
        },
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject({ results: AUTHENTICATE_RESULTS.FAILURE, ...err });
        },
        newPasswordRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.NEW_PASSWORD, ...data });
        },
        mfaRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.MFA_REQUIRED, ...data });
        },
        totpRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.TOTP_REQUIRED, ...data });
        },
        mfaSetup: (data) => {
          this.loadingService.setStatus(false);
          this.setupMFA = true;
          resolve({ results: AUTHENTICATE_RESULTS.MFA_SETUP, ...data });
        },
        selectMFAType: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.SELECT_MFA_TYPE, ...data });
        }
      });
    });

  }

  /**
   * create a new password for current user
   * @param newPassword new password for the current user
   * @returns promise containing data and current user authentication state
   */
  public async createNewPassword(newPassword: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.setupMFA = false;
      this.loadingService.setStatus(true);
      this.cognitoUser.completeNewPasswordChallenge(newPassword, [], {
        onSuccess: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.SUCCESS, ...data });
        },
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject({ results: AUTHENTICATE_RESULTS.FAILURE, err });
        },
        newPasswordRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.NEW_PASSWORD, ...data });
        },
        mfaRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.MFA_REQUIRED, ...data });
        },
        totpRequired: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.TOTP_REQUIRED, ...data });
        },
        mfaSetup: (data) => {
          this.setupMFA = true;
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.MFA_SETUP, ...data });
        },
        selectMFAType: (data) => {
          this.loadingService.setStatus(false);
          resolve({ results: AUTHENTICATE_RESULTS.SELECT_MFA_TYPE, ...data });
        }
      });
    });

  }

  /**
   * forgot password flow for a user
   * @param Username email of the account to reset
   * @returns promise that returns data or error message from cognito
   */
  public async forgotPassword(Username: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      this.cognitoUser = user;
      this.loadingService.setStatus(true);
      user.forgotPassword({
        onSuccess: (data) => {
          this.loadingService.setStatus(false);
          resolve(data);
        },
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject(err);
        }
      });
    });
  }

  /**
   * reset password flow for a user
   * @param verificationCode code sent to email of user
   * @param newPassword new password to replace the forgotten password
   * @returns promise that contains data or error message as cognito response
   */
  public async resetPassword(verificationCode: string, newPassword: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      const user = this.cognitoUser;
      this.loadingService.setStatus(true);
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess: (data) => {
          this.loadingService.setStatus(false);
          resolve(data);
        },
        onFailure: (data) => {
          this.loadingService.setStatus(false);
          reject(data);
        }
      });
    });
  }

  /**
   * associate a TOTP token to a user account
   * @returns the secret code used to build the TOTP QR code to be used with an authentication app
   */
  public async associateToken(): Promise<any> {
    return await new Promise((resolve, reject) => {
      const user = this.cognitoUser;
      this.loadingService.setStatus(true);
      user.associateSoftwareToken({
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject(err);
        },
        associateSecretCode: (secretCode) => {
          this.loadingService.setStatus(false);
          resolve(secretCode);
        }
      })
    })

  }

  /**
   * verifies that token value matches when setting up MFA for the first time
   * NOT USED WHEN LOGGING IN, ONLY FOR SET UP
   * @param totp TOTP value from authenticator app
   * @returns promise containing error message in case of error, user data in case of success
   */
  public async verifyTOTP(totp: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.loadingService.setStatus(true);
      this.cognitoUser.verifySoftwareToken(totp, undefined, {
        onSuccess: () => {
          const totpMfaSettings: IMfaSettings = {
            PreferredMfa: true,
            Enabled: true
          }
          this.cognitoUser.setUserMfaPreference(undefined, totpMfaSettings, (err, result) => {
            if (err) {
              this.loadingService.setStatus(false);
              reject(err);
            }
            this.loadingService.setStatus(false);
            resolve(result);
          });
        },
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject(err);
        }
      });
    });
  }

  /**
   * send that TOTP value when logging in
   * NOT USED FOR SET UP, ONLY FOR LOGGING IN
   * @param totp TOTP value from the authenticator app
   * @returns promise containing error message in case of error, user data in case of success
   */
  public async submitTOTP(totp: string): Promise<any> {
    return await new Promise((resolve, reject) => {
      this.loadingService.setStatus(true);
      this.cognitoUser.sendMFACode(totp, {
        onSuccess: (result) => {
          this.loadingService.setStatus(false);
          resolve(result);
        },
        onFailure: (err) => {
          this.loadingService.setStatus(false);
          reject(err);
        }
      }, 'SOFTWARE_TOKEN_MFA')
    });
  }

  /**
   * logout user, clear local and session data
   */
  public logout() {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
    }
    if (this.cognitoUser) {
      delete this.cognitoUser;
    }
    this.commonNavigations.navigateToLogin();
  }

  /**
   * Generates an API key for the user after a successful login if no API key exists already
   * @returns the api key for the specific user
   */
  public async createApiKey() {
    const session = await this.getSession();
    const url = `${this.settings.apiKeyUrl}?sub=${session.sub}`;
    this.removeEmptyHeaders(session.headers);
    const httpHeaders = new HttpHeaders(session.headers);
    return this.http.get(url, { headers: httpHeaders });

  }

  /**
   * removes headers with empty values
   * @param headers headers that need to be cleaned
   */
  private removeEmptyHeaders(headers) {
    Object.keys(headers).forEach(headerKey => {
      if (!headers[headerKey]) {
        delete headers[headerKey];
      }
    });
  }

  /**
   * finds the current user from cache or from the pool
   * @returns current user
   */
  private getCurrentUser() {
    return (this.cognitoUser) ? this.cognitoUser : Pool.getCurrentUser();
  }

  /**
   * returns the current state of the MFA setup
   */
  public get isMFASetup() {
    return this.setupMFA;
  }
}
