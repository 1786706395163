<form [formGroup]="form" autocomplete="off" *ngIf="partnerFields && partner">
  <ng-container *ngIf="!partner.id?.length">
    <ui-input [form]="form" [field]="partnerFields.eventId" *ngIf="partnerFields.eventId"></ui-input>
    <ui-input [form]="form" [field]="partnerFields.eventTitle" *ngIf="partnerFields.eventTitle"></ui-input>
    <ui-input [form]="form" [field]="partnerFields.startDate" *ngIf="partnerFields.startDate"></ui-input>
    <ui-input [form]="form" [field]="partnerFields.dueDate" *ngIf="partnerFields.dueDate"></ui-input>
  </ng-container>
  <ui-input [form]="form" [field]="partnerFields.partnerName" *ngIf="partnerFields.partnerName"></ui-input>
  <ui-input [form]="form" [field]="partnerFields.company" *ngIf="partnerFields.company"></ui-input>
  <ui-input [form]="form" [field]="partnerFields.phone" *ngIf="partnerFields.phone"></ui-input>
  <ui-input [form]="form" [field]="partnerFields.email" *ngIf="partnerFields.email"></ui-input>
  <ui-input [form]="form" [field]="partnerFields.partnerType" *ngIf="partnerFields.partnerType" class="d-none"></ui-input>
  <fieldset class="form-group">
    <label class="form-control-label font-weight-bold required" [for]="partnerFields.partnerType.name">
      {{partnerFields.partnerType.label}}
    </label>
    <div class="btn-group btn-group-justified mb-20" id="partner-type" data-toggle="buttons">
      <ng-container *ngFor="let type of partnerTypeOptions; let idx = index">
        <label class="btn btn-default mb-0" [class.active]="type.checked" [for]="'partner-type-'+type.value">
          <input [tabindex]="idx" [id]="'partner-type-'+type.value" [type]="type.type" [name]="type.name" [value]="type.value"
            autocomplete="off" class="d-none" (change)="changeSelectValue($event)">
          <span class="white-space-normal" tmpclass="PSRADIOBUTTON">{{type.value}}</span>
        </label>
      </ng-container>
    </div>
  </fieldset>
  <ui-textarea [form]="form" [field]="partnerFields.specialties" *ngIf="partnerFields.specialties"></ui-textarea>
  <ui-textarea [form]="form" [field]="partnerFields.adminComment" *ngIf="partnerFields.adminComment"></ui-textarea>
</form>
