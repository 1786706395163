import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Http, StartHttpEvent, EndHttpEvent, ErrorHttpEvent } from '@inflight/core-ng';
import { HttpService } from '../http.service';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  status: boolean = true;
  private loadingSubject: BehaviorSubject<boolean>;
  private loadingObservable: Observable<boolean>;

  constructor(
    private http: HttpService
  ) {
    this.loadingSubject = new BehaviorSubject<boolean>(undefined);
    this.loadingObservable = this.loadingSubject.asObservable();
    this.subscribeToHttpEvents();
  }

  subscribeToHttpEvents() {
    this.http.httpEvents.subscribe(evt => {
      if (evt instanceof StartHttpEvent) {
        if (evt.notify) {
          this.loadingSubject.next(true);
        }
      } else if (evt instanceof EndHttpEvent || evt instanceof ErrorHttpEvent) {
        if (evt.notify) {
          this.loadingSubject.next(false);
        }
      }
    });
  }

  setStatus(status: boolean) {
    this.status = status;
    this.loadingSubject.next(status);
  }

  get isLoading(): Observable<boolean> {
    return this.loadingObservable
  }
}
