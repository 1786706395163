import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContainerLayoutComponent } from './container/container-layout.component';
import { DashboardLayoutComponent } from './dashboard-layout/dashboard-layout.component';
import { NavbarComponent } from './navbar/navbar.component';

import { UINgModule } from '@inflight/ui-ng';
import { PSUINgModule } from '@inflight/ps-ui-ng';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UINgModule,
    PSUINgModule,
    SharedModule
  ],
  declarations: [
    ContainerLayoutComponent,
    DashboardLayoutComponent,
    NavbarComponent
  ]
})
export class LayoutsModule { }
