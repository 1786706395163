import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PasswordComponent } from './password/password.component';
import { TranslateModule } from '@ngx-translate/core';
import { FooterComponent } from './footer/footer.component';
import { UINgModule } from '@inflight/ui-ng';
import { PSUINgModule } from '@inflight/ps-ui-ng';
import { PartnerFormComponent } from './partner-form/partner-form.component';
import { LoadingService } from './loading-service/loading.service';
import { CreateUIFieldsService } from './create-ui-fields.service';
import { CardHeaderComponent } from './card-header/card-header.component';


@NgModule({
  imports: [
    TranslateModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    UINgModule,
    PSUINgModule,
  ],
  declarations: [
    PasswordComponent,
    FooterComponent,
    PartnerFormComponent,
    CardHeaderComponent
  ],
  exports: [
    PasswordComponent,
    FooterComponent,
    PartnerFormComponent,
    CardHeaderComponent
  ],
  providers: [LoadingService, CreateUIFieldsService]
})
export class SharedModule { }
