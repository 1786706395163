import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) { }

  /**
   * determines if a route is allowed to be navigated to or not
   * @param _route route associated with component being loaded
   * @param _state state of router
   * @returns true if route is allowed or a URL tree to the login page if cannot go to the specific route
   */
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): boolean | UrlTree | Promise<boolean | UrlTree> {
    return new Promise((resolve) => {
      this.sessionService.getSession().then(data => {
        if (data) {
          resolve(true);
        }
        resolve(this.router.parseUrl('/login'));
      }).catch(() => {
        resolve(this.router.parseUrl('/login'));
      }
      )
    });
  }

  /**
   * determines if a child route can be activated or not
   * @param route route associated with component being loaded
   * @param state state of router
   * @returns true if route is allowed or a URL tree to the login page if cannot go to the specific route
   */
  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    return this.canActivate(route, state);
  }
}
