import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { IError, IInputField } from '@inflight/core-models';
import { SettingsService } from '@inflight/ps-core-ng';
import { PasswordCriterion, PasswordCriterionValidatorService } from '@inflight/ui-ng';
import { CreateUIFieldsService } from '../create-ui-fields.service';
import { UpdateFormValidity } from '../update-form-validity';

@Component({
  selector: 'app-shared-password',
  templateUrl: './password.component.html'
})
export class PasswordComponent implements OnInit {

  @Input() form: FormGroup;
  @Output() submit = new EventEmitter<any>();

  passwordsRequirements = {
    lowercase: false,
    uppercase: false,
    special: false,
    number: false,
    minLength: false
  };
  error: IError;
  settings: any;
  passwordCriteria: any;
  passwordFields: any;

  constructor(
    private passwordValidatorService: PasswordCriterionValidatorService,
    private createUIField: CreateUIFieldsService,
    settingsService: SettingsService
    ) {
      this.settings = settingsService.getExt('password');
    }

  async ngOnInit() {
    const passwordFields = await this.createUIField.createFieldsFromName({password: '', passwordRepeat: ''}, 'input', this.settings);
    this.passwordCriteria = this.getAllPasswordCriteria();
    this.passwordValidatorService.setCriteria(this.passwordCriteria);
    this.passwordValidatorService.setDefaultCriteriaStatus('initial');
    this.passwordFields = passwordFields;
  }

  /**
   * check validity of form and emit in order to submit
   */
  submitForm() {
    if (this.form.valid) {
      this.submit.emit();
    } else {
      UpdateFormValidity(this.form);
    }
  }

  /**
   * validation function to see if password and repeat password are identical
   * @returns null is passwords are identical, validation error if they're not
   */
  checkPasswordsIdentical(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const password = control.get('password');
      const passwordRetype = control.get('passwordRepeat');
      if (password?.value !== passwordRetype.value || passwordRetype.value.length === 0) {
        passwordRetype.setErrors({ passwordsNotMatching: true });
        if (passwordRetype.value.length > 0) {
          this.error = {
            message: 'error.passwords_not_identical.message'
          };
        }
        return { passwordsNotMatching: true };
      }
      passwordRetype.setErrors(null);
      this.error = undefined;
      return null;
    }
  }

  /**
   * function to create password criteria, need 8+ characters, 1 upper and lowercase, 1 numeric, 1 special
   * @returns array with all the password criteria
   */
  getAllPasswordCriteria() {
    const criteria = [
      {
        title: 'Minimum 8 characters',
        description: 'Must contain at least 8 characters',
        validator: (pwdValue: string) => {
          return pwdValue && pwdValue.length && pwdValue.length >= 8;
        }
      },
      {
        title: '1 uppercase & lowercase',
        description: 'Must contain at least 1 uppercase and lowercase letter',
        validator: (pwdValue: string) => {
          const lowerRegex = /[a-z]/;
          const upperRegex = /[A-Z]/;
          return pwdValue && pwdValue.length && pwdValue.match(lowerRegex) && pwdValue.match(upperRegex);
        }
      },
      {
        title: '1 numeric character',
        description: 'Must contain at least 1 numeric character',
        validator: (pwdValue: string) => {
          const digitRegex = /[0-9]/;
          return pwdValue && pwdValue.length && pwdValue.match(digitRegex);
        }
      },
      {
        title: '1 special character',
        description: 'Must contain at least 1 special character',
        validator: (pwdValue: string) => {
          const digitRegex = /[^a-zA-Z0-9]/;
          return pwdValue && pwdValue.length && pwdValue.match(digitRegex);
        }
      }
    ] as PasswordCriterion[];

    return criteria;
  }

  /**
   * update password criteria validation
   * @param field password IInputField
   */
  checkPasswordValidation(field: IInputField) {
    if (!field?.value) {
      return;
    }
    this.passwordValidatorService.updateCriteriaValidationStatus(field.value);
  }
}
