import { FormGroup } from "@angular/forms";

/**
   * updates all status and validity of all the form controls
   * @param form contains all find partner request information
   */
 export function UpdateFormValidity(form: FormGroup) {
  Object.keys(form.controls).forEach((ctrl) => {
    const formCtrl = form.controls[ctrl];
        formCtrl.markAsTouched();
        formCtrl.updateValueAndValidity();
  });
  form.updateValueAndValidity();
}
