<nav class="navbar navbar-dark bg-dark">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarText">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/partners/create'">{{'layouts.navbar.create_partner_link' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="'/partners/view'">{{'layouts.navbar.view_partners_link' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" (click)="signout()" href="javascript:void(0);">{{'layouts.navbar.sign_out_link' | translate}}</a>
      </li>
    </ul>
  </div>
  <a class="navbar-brand" [routerLink]="'/'">
    <div class="navbar-logo">
      <img class="logo p-1 p-md-3" src="assets/themes/ccsf/images/admin-logo.svg" alt="{{'layouts.navbar.logo_image_alt' | translate}}"/>
    </div>
  </a>
</nav>
