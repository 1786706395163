/**
 * Helper code to get the user pool
 */
import { CognitoUserPool, ICognitoUserPoolData } from 'amazon-cognito-identity-js';

declare var window;

const poolData = {
  UserPoolId: window.cognitoConfig.cognitoUserPoolId,
  ClientId: window.cognitoConfig.cognitoAppClientId
} as ICognitoUserPoolData;

export default new CognitoUserPool(poolData);
