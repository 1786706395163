import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from './shared/loading-service/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'partner-crud-app';
  loading = false;

  constructor(
    private translate: TranslateService,
    private loadingService: LoadingService
  ) {
    this.setLanguage();
    this.subscribeToServices();
  }

  /**
   * sets language for internalization
   */
  setLanguage() {
    const defaultLang = 'en';
    this.translate.setDefaultLang(defaultLang);
    this.translate.use(defaultLang);
  }

  /**
   * subscribing to app-level subscriptions
   */
  subscribeToServices() {
    this.loadingService.isLoading.subscribe(loading => {
      this.loading = loading;
    });
  }

}
