import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { partnerDB } from 'src/app/partner/api.service';
import { IInputField } from '@inflight/core-models';
import { SettingsService } from '@inflight/ps-core-ng';
import { CreateUIFieldsService } from '../create-ui-fields.service';
import { UpdateFormValidity } from '../update-form-validity';

@Component({
  selector: 'app-partner-form',
  templateUrl: './partner-form.component.html'
})
export class PartnerFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() partner: partnerDB;
  @Output() submit = new EventEmitter<any>();

  partnerFields: any;
  partnerTypeOptions: IInputField[];
  settings: any;

  constructor(
    settingsService: SettingsService,
    private createUIFields: CreateUIFieldsService
  ) {
    this.settings = settingsService.getExt('partners').form;
  }

  ngOnInit() {
    if (this.partner.id) {
      this.form.addControl('id', new FormControl(this.partner.id));
      this.form.addControl('isPublished', new FormControl(this.partner.isPublished));
    }
    this.preparePartnerFields();
  }

  /**
   * setup partner fields object to be used in template
   */
  async preparePartnerFields() {
    const partnerFields = await this.createUIFields.createFieldsFromName(this.partner, 'input', this.settings);
    this.partnerTypeOptions = this.preparePartnerTypeOptions(partnerFields);
    this.partnerFields = partnerFields;
  }

  /**
   * validate and emit form to parent component for submission
   */
  onSubmitEdit() {
    if (this.form.valid) {
      this.submit.emit();
    } else {
      UpdateFormValidity(this.form);
    }
  }

  /**
   * prepares partner type radios and values
   * @param partnerFields object containing all of the form properties and their initial values
   * @returns array of radios for the two possible partner type values
   */
  preparePartnerTypeOptions(partnerFields) {
    const radios = [] as IInputField[];

    const basicInput = {
      disabled: false,
      id: '',
      name: 'partnerType',
      type: 'radio',
      value: '',
      nodeType: 'input',
      label: '',
      required: false
    } as IInputField;

    const radioValues = this.settings.partnerTypeValues;
    radioValues.forEach(value => {
      const radioInput = {...basicInput, value};
      if (partnerFields.partnerType.value === value) {
        radioInput.checked = true;
      }
      radios.push(radioInput);
    });

    return radios;
  }

  /**
   * update form value to be the one of checked radio
   * @param radioRef html element reference for radio that was checked
   */
  changeSelectValue(radioRef: Event) {
    const htmlRadio = radioRef.target as HTMLInputElement;
    const value = htmlRadio.value;
    const name = htmlRadio.name;
    this.form.get(name).setValue(value);
  }
}
